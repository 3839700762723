import { createColumnHelper } from "@tanstack/react-table";
import { filesize } from "filesize";
import { numberWithCommasWithoutDecimal } from "../../Helper";

type Token = {
  dataTokenOwner: { id: string; phoneNumber: string };
  code: string;
  bytesAllocated: number;
  bytesUsed: number;
  secondsAllocated: string;
  dataTokenUsageType: string;
  voucherStatus: string;
};

const columnToken = createColumnHelper<Token>();

export const columnsToken = [
  columnToken.accessor("dataTokenOwner", {
    header: "Assigned To",
    cell: (val) => {
      const owner = val.getValue();
      return <p>{owner?.phoneNumber ? owner?.phoneNumber : "Not Assigned"}</p>;
    },
  }),

  columnToken.accessor("code", {
    header: "Token",
  }),

  columnToken.accessor("bytesAllocated", {
    header: "Data Allocated",
    cell: (val) => {
      const bytesAllocated = val.getValue();
      return filesize(bytesAllocated || 0);
    },
  }),

  columnToken.accessor("bytesUsed", {
    header: "Data Used",
    cell: (val) => {
      const bytesUsed = val.getValue();
      return filesize(bytesUsed || 0);
    },
  }),

  columnToken.accessor("secondsAllocated", {
    header: "Validity Period",
    cell: (val) => {
      const validityPeriod = val.getValue();
      return <p>{numberWithCommasWithoutDecimal(Number(validityPeriod) || 0) + "secs"}</p>;
    },
  }),

  columnToken.accessor("dataTokenUsageType", {
    header: "Usage Type",
  }),
  columnToken.accessor("voucherStatus", {
    header: "Status",
    cell: (info) => {
      const status = info.getValue();
      let color = "";
      if (status.toLowerCase() === "active") {
        color = "#00C087";
      } else if (status.toLowerCase() === "exhausted") {
        color = "#DB5F5F";
      } else if (status.toLowerCase() === "inactive") {
        color = "#CFA809";
      }

      return (
        <p
          style={{
            color,
            fontWeight: "600",
          }}
        >
          {status}
        </p>
      );
    },
  }),
];
