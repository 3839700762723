import { useState } from "react";
import { useAppSelector } from "../../app/hooks";
import { Button } from "../reusables";
import CopyIcon from "../../assets/icons/copy.svg";
import ProfilePic from "../../assets/profile-pic.svg";
import Edit from "../../assets/edit.svg";
import { useNavigate } from "react-router";
import { useGetBusinessProfileQuery } from "../../features/api/authSlice";
import { useUpdateProfileMutation } from "../../features/api/profileSlice";
import { copyToClipboard } from "../../Helper";

const Profile = () => {
  const navigate = useNavigate();
  const [profileUrl, setprofileUrl] = useState<string>("");
  const { name, email, verificationStatus, refCode, profileImageUrl } =
    useAppSelector((state) => state.auth);
  const {
    data: businessProfile,
    isError,
    isLoading,
  } = useGetBusinessProfileQuery();
  const [copiedText, setCopied] = useState<string>("");
  const [updateProfile] = useUpdateProfileMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      //setBusinessLogoFile(selectedFile);
      setprofileUrl(blobUrl);
      handleProfileUpdate();
    }
  };

  const handleProfileUpdate = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", "");
    bodyFormData.append("LastName", "");
    bodyFormData.append("PhoneNumber", "");
    bodyFormData.append("ProfileImage", profileUrl);
    updateProfile(bodyFormData);
  };

  return (
    <section className="w-[96%] mx-auto pt-3 pb-6 h-full overflow-y-auto">
      <div className="flex flex-wrap gap-10 px-4 py-4 bg-white dark:bg-clrDarkBg rounded-t-md md:rounded-md md:px-12 md:gap-24 md:flex-nowrap md:py-7 profile-bg ">
        <div className="flex flex-col items-center w-full md:w-fit md:pt-2 gap-7">
          <div className="relative">
            <img
              src={profileImageUrl ? profileImageUrl : ProfilePic}
              alt="Profile picture"
              width={150}
              height={150}
              className="rounded-full w-36 pic-aspect"
            />
            <button className="absolute right-0 cursor-pointer bottom-6">
              <div className="flex items-center justify-center w-6 h-6 rounded-full cursor-pointer bg-clrTextBlue dark:bg-clrDarkYellow">
                <img
                  src={Edit}
                  alt="Edit your profile here"
                  width={15}
                  height={15}
                />
                <input
                  type="file"
                  name="file"
                  id="idCard"
                  onChange={handleChange}
                  accept=".png, .jpeg, .jpg"
                  className="absolute overflow-hidden opacity-0 cursor-pointer z-index--1"
                />
              </div>
            </button>
          </div>
          {verificationStatus !== "VERIFIED" && (
            <div className="w-1/2 md:w-4/5">
              <Button
                type="button"
                text="Verify Account"
                handleClick={() => navigate("/home/profile/verifyID")}
                textSize="text-x9"
                py="py-2"
                fontWeight="font-normal"
              />
            </div>
          )}
        </div>
        <div className="space-y-4 md:space-y-6">
          <div>
            <h3 className="mb-1 md:mb-1.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              NAME:
            </h3>
            <p className="text-xs font-medium md:font-semibold text-clrGray dark:text-white">
              {name}
            </p>
          </div>
          <div>
            <h3 className="mb-1 md:mb-1.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
              EMAIL
            </h3>
            <p className="text-xs font-medium md:font-semibold text-clrGray dark:text-white">
              {email}
            </p>
          </div>

          {!isLoading && !isError && (
            <>
              <div>
                <h3 className="mb-1 md:mb-1.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                  BUSINESS NAME
                </h3>
                <p className="text-sm font-medium text-black md:text-base md:font-semibold dark:text-white">
                  {businessProfile?.data.name}
                </p>
              </div>
              <div>
                <h3 className="mb-1 md:mb-1.5 font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                  BUSINESS ADDRESS
                </h3>{" "}
                <p className="text-sm font-medium text-black md:text-base md:font-semibold dark:text-white">
                  {businessProfile?.data.officeAddress}
                </p>
              </div>
            </>
          )}
        </div>
      </div>
      {!isLoading && !isError && (
        <div className="flex flex-col gap-4 px-4 pb-6 bg-white dark:bg-clrDarkBg rounded-b-md md:rounded-md md:px-8 md:py-6 md:mt-2 md:gap-7 second-profile-bg">
          <div className="flex flex-wrap md:flex-nowrap gap-y-4 gap-x-[10.5rem] md:pb-7 md:border-b-[0.0625rem] md:border-solid md:border-clrBorder">
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-xs md:mb-2 text-clrGray dark:text-clrPlaceholder">
                REFERRAL CODE:
              </h3>
              <p className="text-base font-semibold md:text-xl md:font-extrabold text-clrTextBlue dark:text-white">
                {refCode}
              </p>
            </div>
            <div>
              <h3 className="mb-1 font-medium text-x10 md:text-xs md:mb-2 text-clrGray dark:text-clrPlaceholder">
                API KEY:
              </h3>
              <p className="relative flex gap-2">
                <span className="text-xs font-medium md:text-sm md:font-semibold text-clrTextBlue dark:text-white">
                  {businessProfile?.data.apiKey}
                </span>
                <img
                  src={CopyIcon}
                  alt="Copy this API key"
                  width={13}
                  onClick={() => {
                    copyToClipboard(businessProfile?.data.apiKey as string);
                    setCopied(businessProfile?.data.apiKey as string);
                  }}
                  height={13}
                />
                {businessProfile?.data.apiKey === copiedText && (
                  <p className="absolute right-0 p-1 mt-4 text-white border-solid rounded bg-clrYellow text-x10 ">
                    {" "}
                    Copied
                  </p>
                )}
              </p>
            </div>
          </div>
          <div>
            <h3 className="mb-1 text-xs font-medium md:mb-2 text-clrGray dark:text-clrPlaceholder">
              BILLLING TYPE
            </h3>
            <p className="text-sm font-medium md:text-base md:font-semibold text-clrGreen3">
              {businessProfile?.data.billingType === 1
                ? "NORMAL"
                : "SUBSCRIPTION"}
            </p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Profile;
