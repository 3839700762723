import { apiSlice } from "./apiSlice";
import { AdSupportRequest, AllDevicesRequest, AllDevicesResponse, BillingRateRequest, DeviceReportRequest, DeviceReportResponse, DeviceSummaryResponse, GetSessionequest, GetSessionSummaryRequest, HubStatsRequest, HubStatsResponse, PaginatedSuccessResponse, SessionSummaryResponse, SuccessResponse } from "./deviceTypes";

const deviceSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get Devices
        getDevices: build.query<AllDevicesResponse, AllDevicesRequest>({
            query: ({deviceName, deviceStatus, page, pageSize}) => `/v2/devices/my?deviceName=${deviceName}&deviceStatus=${deviceStatus}&page=${page}&pageSize=${pageSize}`,
            providesTags: ["Devices"]
        }),

        //Get Session Summary
        getSessionSummary: build.query<SessionSummaryResponse, GetSessionSummaryRequest>({
            query: ({ isActive, searchParam, deviceId, userId, businessid, startDateTimeStamp, endDatetimeStamp }) =>
                `/v2/devices/sessions/summary?isActive=${isActive}&searchParam=${searchParam}&deviceId=${deviceId}&userId=${userId}&businessid=${businessid}&startDateTimeStamp=${startDateTimeStamp}&endDatetimeStamp=${endDatetimeStamp}`,
            providesTags: ["Devices"]
        }),

        //Get User Sessions
        getSessions: build.query<PaginatedSuccessResponse, GetSessionequest>({
            query: ({ isActive, pageSize, page, startDateTimeStamp, endDatetimeStamp, deviceName }) =>
                `/v2/devices/Sessions/user?isActive=${isActive}&pageSize=${pageSize}&page=${page}&startDateTimeStamp=${startDateTimeStamp}&endDatetimeStamp=${endDatetimeStamp}&deviceName=${deviceName}`,
            providesTags: ["Devices"]
        }),

        //Get Device Details
        getDeviceSummary: build.query<DeviceSummaryResponse, void>({
            query: () => `/v4/hotspot-hubs/status`,
            providesTags: ["Devices"]
        }),

        //Get device report
        getDeviceReport: build.query<DeviceReportResponse, DeviceReportRequest>({
            query: ({ deviceName, startDate, endDate, page, pageSize }) => `/v2/users/devices/summary/by?deviceName=${deviceName}&startDate=${startDate}&endDate=${endDate}&Page=${page}&PageSize=${pageSize}`,
            providesTags: ["Devices"]
        }),

        //Delete Device
        deleteDevice: build.mutation<SuccessResponse, Partial<string>>({
            query: (id) => ({
                url: `/v2/devices/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Devices"]
        }),

         //Device Stats
         getDeviceStats: build.query<HubStatsResponse, HubStatsRequest>({
            query: ({hubId, search, duration, startDateTimeStamp, endDatetimeStamp }) => `/v4/hotspot-hubs/stats?HubId=${hubId}&Duration=${duration}&Search=${search}&StartDateTimeStamp=${startDateTimeStamp}&EndDateTimeStamp=${endDatetimeStamp}`,
            providesTags: ["Devices"]
        }),

        //Toggle ad support
        toggleAdSupport: build.mutation<SuccessResponse, Partial<AdSupportRequest>>({
            query: (payload) => ({
                url: `/v2/devices/adSupport`,
                method: 'PUT',
                body: payload
            }),
        }),

        //Set billing rate for device
        setBillingRate: build.mutation<any, Partial<BillingRateRequest>>({
            query: (payload) => ({
                url: `/v4/hotspot-hubs/billing-rate`,
                method: 'PUT',
                body: payload
            }),
            invalidatesTags: ["Devices"]
        }),

       
    }),
    overrideExisting: true,
})

export const { useGetDevicesQuery, useGetSessionSummaryQuery, useToggleAdSupportMutation, 
    useGetSessionsQuery, useSetBillingRateMutation, useDeleteDeviceMutation, 
    useGetDeviceSummaryQuery, useGetDeviceStatsQuery, useGetDeviceReportQuery } = deviceSlice