import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonOutlineWallet } from "../reusables";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import { ReactComponent as FundIcon } from "../../assets/icons/wallet/wallet-download.svg";
import { ReactComponent as WithdrawIcon } from "../../assets/icons/wallet/wallet-upload.svg";
import { ReactComponent as SwapIcon } from "../../assets/icons/wallet/wallet-wallet-transfer.svg";
import { ReactComponent as StakeIcon } from "../../assets/icons/wallet/wallet-stake.svg";
import { ReactComponent as ClosedEye } from "../../assets/auth/eye-closed.svg";
import { ReactComponent as OpenEye } from "../../assets/auth/eye-open.svg";
import {
  useGetBalancesQuery,
  useLazyGetSupportedChannelsQuery,
} from "../../features/api/walletSlice";
import { numberWithCommas } from "../../Helper";
import { ScaleLoader } from "react-spinners";
import {
  ChooseCurrency,
  ChooseWalletMethod,
  ChoosePreferredMethod,
  CryptoFundingDetail,
} from "./Others";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { Swap } from "./SwapFlow";

import { selectCurrency } from "../../features/store/walletReducerSlice";
import { Currency } from "../../features/api/walletSliceTypes";
import TransferFlow from "./TransferFlow";
import PreviewHistory from "./transactionHistory/PreviewHistory";

interface OptionProps {
  title: string;
  description: string;
  type: "FUNDING" | "WITHDRAWAL" | "STAKE" | "SWAP" | "";
}

const Wallet = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  //Set Transfer steps
  const [step, setStep] = useState<number>(0);

  //toggle view or hide for the balance
  const [isView, setView] = useState<boolean>(false);
  const [isBalanceView, setBalanceView] = useState<boolean>(false);

  //Set transaction option
  const [isOption, setOption] = useState<OptionProps>({
    title: "",
    description: "",
    type: "",
  });

  //Control the swap modal
  const [swapStep, setSwapStep] = useState<number>(0);

  const { id: userId } = useAppSelector((state) => state.auth);
  const { selectedCurrency: currency } = useAppSelector(
    (state) => state.wallet
  );

  //Get user's total balance
  const { data: balances, isLoading: loadingBalance } =
    useGetBalancesQuery(userId);

  //Get supported channels
  const [trigger, { data: supportedChannels, isLoading: loadingChannels }] =
    useLazyGetSupportedChannelsQuery();

  const [isChooseMoreFund, setIsChooseMoreFund] = useState(false);
  const [isChooseWalletMethod, setIsChooseWalletMethod] = useState(false);
  const [isWalletAddress, setIsWalletAddress] = useState(false);

  const modalRef = useRef<HTMLElement>(null);

  // function to close all modals
  const handleCloseModal = () => {
    setOption({ title: "", description: "", type: "" });
    setSwapStep(0);
    setIsChooseMoreFund(false);
    setIsWalletAddress(false);
    setIsChooseWalletMethod(false);
  };

  const handleP2P = () => {
    navigate("/home/wallet/peer-to-peer");
  };

  const handleOpenStake = () => {
    navigate("/home/wallet/stake");
  };

  const handleWithdrawP2P = () => {
    navigate("/home/wallet/withdraw-P2P");
  };

  const handleCrypto = () => {
    navigate("/home/wallet/crypto-withdraw");
  };

  const handleSelected = (
    type: "FUNDING" | "WITHDRAWAL" | "STAKE" | "SWAP" | "",
    currency: Currency
  ) => {
    dispatch(selectCurrency(currency));
    switch (type) {
      case "FUNDING":
        trigger({ category: "Funding", currencyId: currency?.id });
        setIsChooseMoreFund(true);
        return;
      case "WITHDRAWAL":
        trigger({ category: "Withdrawal", currencyId: currency?.id });
        setIsChooseWalletMethod(true);
        return;
      case "SWAP":
        setSwapStep(1);
        return;
      case "STAKE":
        handleOpenStake();
        return;
      default:
        break;
    }
  };

  useOutsideClick(modalRef, handleCloseModal);

  return (
    <section className="w-[96%] mx-auto pb-6 mt-3 overflow-y-auto">
      <div className="flex flex-wrap gap-2 mb-2 md:flex-nowrap">
        <div className="flex flex-col justify-between w-full p-4 bg-white rounded-lg dark:bg-clrDarkBg md:px-8 md:py-6 h-44 md:h-auto md:basis-4/6">
          <div className="flex items-end justify-between">
            <div>
              <div className="flex items-center gap-2 mb-1">
                <h3 className="text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
                  Total value
                </h3>
                {isView ? (
                  <OpenEye
                    className="cursor-pointer"
                    height="15px"
                    width="15px"
                    onClick={() => setView(!isView)}
                  />
                ) : (
                  <ClosedEye
                    className="cursor-pointer"
                    height="15px"
                    width="15px"
                    onClick={() => setView(!isView)}
                  />
                )}
              </div>
              <p className="text-2xl font-bold text-clrTextBlue dark:text-white">
                {"USD "}
                {loadingBalance ? (
                  <ScaleLoader
                    loading={loadingBalance}
                    height={12}
                    width={2}
                    color={"#3d5170"}
                  />
                ) : !isView ? (
                  "****"
                ) : (
                  numberWithCommas(
                    balances ? balances?.data.usdWorthOfAllBalances : 0,
                    2
                  )
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-wrap gap-3 md:self-end">
            <ButtonOutlineWallet
              type="button"
              text="Fund"
              handleClick={() =>
                setOption({
                  title: "Fund Wallet",
                  description: "Select preferred currency to fund",
                  type: "FUNDING",
                })
              }
              showIcon={true}
              btnIcon={<FundIcon />}
              bgColor="bg-clrYellow2"
              textColor="text-white"
            />
            <ButtonOutlineWallet
              type="button"
              text="Withdraw"
              handleClick={() =>
                setOption({
                  title: "Withdraw Wallet",
                  description: "Select preferred withdraw currency",
                  type: "WITHDRAWAL",
                })
              }
              showIcon={true}
              btnIcon={<WithdrawIcon />}
            />
            <ButtonOutlineWallet
              type="button"
              text="Swap"
              handleClick={() =>
                setOption({
                  title: "Swap",
                  description: "Select preferred transfer currency",
                  type: "SWAP",
                })
              }
              showIcon={true}
              btnIcon={<SwapIcon />}
            />
            <ButtonOutlineWallet
              type="button"
              text="Stake"
              handleClick={() =>
                setOption({
                  title: "Stake",
                  description: "Stake your WNT to earn interest",
                  type: "STAKE",
                })
              }
              showIcon={true}
              btnIcon={<StakeIcon />}
            />
          </div>
        </div>
        <div className="w-full p-4 space-y-6 bg-white rounded-lg dark:bg-clrDarkBg md:px-6 md:basis-2/6 balance">
          <div className="flex items-center gap-2">
            <h3 className="text-xs font-semibold md:text-sm text-clrGray dark:text-clrPlaceholder">
              Balance
            </h3>
            {isBalanceView ? (
              <OpenEye
                height="15px"
                width="15px"
                className="cursor-pointer"
                onClick={() => setBalanceView(!isBalanceView)}
              />
            ) : (
              <ClosedEye
                height="15px"
                width="15px"
                className="cursor-pointer"
                onClick={() => setBalanceView(!isBalanceView)}
              />
            )}
          </div>
          {/* TODO: Put this in a component  */}
          {loadingBalance ? (
            <ScaleLoader
              loading={loadingBalance}
              height={12}
              width={2}
              color={"#3d5170"}
            />
          ) : (
            balances?.data.balances.map((each) => (
              <div key={each.symbol} className="flex gap-2">
                <div className="w-[30px] h-[30px] rounded-[50%] bg-clrIconBg flex justify-center items-center">
                  <img src={each.iconUrlSvg} alt="WNT" width={30} height={30} />
                </div>

                <div className="mr-auto">
                  <p className="text-xs font-semibold md:text-sm text-clrTextGray dark:text-white">
                    {each.symbol}
                  </p>
                  <p className="font-semibold text-x10 md:text-xs text-clrPlaceholder">
                    {each.name}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="self-end text-sm font-semibold text-clrTextGray dark:text-white">
                    {!isBalanceView
                      ? "****"
                      : numberWithCommas(each.balance, 2)}
                  </p>
                  <p className="self-end font-semibold text-x10 md:text-xs text-clrPlaceholder">
                    {" "}
                    {!isBalanceView
                      ? "****"
                      : "$" + numberWithCommas(each.usdBalances, 2)}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <PreviewHistory />
      {/* handles choosing currrency for all transaction types */}
      {isOption.title && (
        <ChooseCurrency
          handleModal={handleCloseModal}
          handleSelectedCurrency={handleSelected}
          title={isOption.title}
          description={isOption.description}
          type={isOption.type}
        />
      )}
      {/* handles currency funding method */}
      {isChooseMoreFund && (
        <ChoosePreferredMethod
          handleCloseModal={handleCloseModal}
          handleModal={() => setIsChooseMoreFund(false)}
          handleCryptoFunding={() => setIsWalletAddress(true)}
          handleP2P={handleP2P}
          channels={supportedChannels?.data.channels || []}
          isLoading={loadingChannels}
          title="Fund Wallet"
          description="Select preferred method of funding"
        />
      )}
      {/* handles showing options for withdrawal */}
      {isChooseWalletMethod && (
        <ChooseWalletMethod
          handleModal={() => setIsChooseWalletMethod(false)}
          handleCloseModal={handleCloseModal}
          handleTransfer={() => {
            setStep(1);
            handleCloseModal();
          }}
          channels={supportedChannels?.data.channels || []}
          handleWithdrawP2P={handleWithdrawP2P}
          handleCrypto={handleCrypto}
          title="Withdraw Wallet"
          isLoading={loadingChannels}
          description="Select preferred method of withdraw"
        />
      )}

      {/* Swap currency flow */}
      {swapStep !== 0 && (
        <Swap
          swapStep={swapStep}
          handleModal={setSwapStep}
          closeModal={handleCloseModal}
          balances={balances?.data.balances}
          selectedCurrency={currency}
        />
      )}

      {/* handles crypto funding, Generating the QRcode and instructions */}
      {isWalletAddress && (
        <CryptoFundingDetail
          modalRef={modalRef}
          handleCloseModal={handleCloseModal}
          currencyId={currency?.id}
          currencyCode={currency?.code}
          handleModal={() => setIsWalletAddress(false)}
        />
      )}

      {/* handle withdrawal by transfer   */}
      {step !== 0 && <TransferFlow step={step} setStep={setStep} />}
    </section>
  );
};

export default Wallet;
