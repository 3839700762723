import { useEffect, useState } from "react";
import { Modal, Modal2 } from "../reusables/Modal";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, ButtonOutlineWallet, ErrorMsg } from "../reusables";
import TickSquare from "../../assets/icons/wallet/tick-square.svg";
import SearchIcon from "../../assets/search.svg";
import { ReactComponent as PaperClip } from "../../assets/paper-clip.svg";
import { ReactComponent as PhotographIcon } from "../../assets/photograph.svg";
import { idCollection, countries } from "./data";
import { openCamera, takePicture } from "../../Helper";

//Function to allow user to choose the ID to verify
export const ChooseID: React.FC<{
  handleCloseModal: () => void;
  handleSubmit: (val: string) => void;
}> = ({ handleSubmit, handleCloseModal }) => {
  return (
    <Modal
      close={true}
      title={"Choose ID"}
      description={"Which kind of ID would you like to use?"}
      handleCloseModal={handleCloseModal}
    >
      <div className="px-1 py-6 bg-white rounded dark:bg-clrDarkBg">
        {idCollection.map((id) => {
          return (
            <button
              onClick={() => handleSubmit(id.id)}
              className="flex items-center justify-between w-full py-3 px-3 rounded hover:bg-clrBg dark:hover:bg-clrDarkBg2"
            >
              <h3 className="font-semibold text-left text-black dark:text-white text-x10 md:text-xs dark:font-medium ">
                {id.name}
              </h3>
              <img
                src={ArrowDown}
                alt="more options"
                width={20}
                height={20}
                className="-rotate-90"
              />
            </button>
          );
        })}
      </div>
    </Modal>
  );
};

//Function to allow user to select a region for verifying the user
export const SelectRegion: React.FC<{
  handleGoBack: () => void;
  handleCloseModal: () => void;
  handleSubmit: (val: string) => void;
}> = ({ handleCloseModal, handleSubmit, handleGoBack }) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredCountries = countries.filter((country) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Modal
      close={true}
      goBack={true}
      title={"Select Region"}
      handleGoBack={handleGoBack}
      handleCloseModal={handleCloseModal}
    >
      <div className="p-6 bg-white rounded dark:bg-clrDarkBg">
        <div className="relative mb-3 ">
          <label htmlFor="search" className="hidden">
            Search
          </label>
          <img
            src={SearchIcon}
            alt="search"
            className="absolute top-2.5 left-5"
            width={13}
            height={13}
          />
          <input
            type="text"
            name="search"
            id=""
            placeholder="Search country"
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full indent-8 bg-clrBg3 border-[1px] border-solid border-clrBorder4 rounded-3xl h-8 px-4 placeholder:text-clrPlaceholder placeholder:text-x10 text-xs outline-none"
          />
        </div>
        <div className="h-40 overflow-auto">
          {searchQuery
            ? filteredCountries.map((country) => {
                return (
                  <button
                    onClick={() => handleSubmit(country.code)}
                    className="py-3 w-full border-b-[0.0625rem] border-solid border-clrBorder3 dark:border-[#DEDEDE80] "
                  >
                    <p className="font-semibold text-left dark:font-medium text-clrGray dark:text-clrPlaceholder text-x9 active:text-clrTextBlue focus:text-clrTextBlue hover:text-clrTextBlue dark:active:text-white dark:focus:text-white dark:hover:text-white">
                      {country.name}
                    </p>
                  </button>
                );
              })
            : countries.map((country) => {
                return (
                  <button
                    onClick={() => handleSubmit(country.code)}
                    className="py-3 w-full border-b-[0.0625rem] border-solid border-clrBorder3 dark:border-[#DEDEDE80]"
                  >
                    <p className="font-semibold text-left dark:font-medium text-clrGray dark:text-clrPlaceholder text-x9 active:text-clrTextBlue focus:text-clrTextBlue hover:text-clrTextBlue dark:active:text-white dark:focus:text-white dark:hover:text-white">
                      {country.name}
                    </p>
                  </button>
                );
              })}
        </div>
      </div>
    </Modal>
  );
};

interface CaptureDocumentProps {
  handleCloseModal: () => void;
  handleSubmit: (val: string) => void;
  handleGoBack: () => void;
  idType: string;
}

export const CaptureDocument: React.FC<CaptureDocumentProps> = ({
  handleGoBack,
  handleCloseModal,
  handleSubmit,
  idType,
}) => {
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [mediaStream, saveStream] = useState<MediaStream>();

  const startCamera = async () => {
    try {
      const startCameraFunction = openCamera();
      const stream = await startCameraFunction();
      // Do something with the stream, e.g., display it on the page
      const videoElement = document.getElementById(
        "videocamera"
      ) as HTMLVideoElement;
      saveStream(stream);
      videoElement.srcObject = stream;
    } catch (error) {
      console.error("Error opening camera:", error);
    }
  };

  const handleCaptureImage = () => {
    try {
      const takePictureFunction = takePicture(mediaStream as MediaStream);
      const imageDataUrl = takePictureFunction();

      setImage(imageDataUrl);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    
    if (selectedFile) {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result as string;
            setImage(base64String);
            setFileName(selectedFile?.name);
        };

        reader.readAsDataURL(selectedFile);
    }
};

  useEffect(() => {
    if (!image) {
      startCamera();
    }
  }, [image]);

  return (
    <Modal
      close={true}
      goBack={true}
      title={"Capture ID"}
      description={`Make sure your ${idType.toLowerCase()} is infront of the frame`}
      handleCloseModal={handleCloseModal}
      handleGoBack={handleGoBack}
    >
      <div className="">
        <div className="flex flex-col items-center justify-center w-full gap-6 bg-black rounded mb-7 h-72">
          <div className="w-56 h-40 border-2 border-white border-solid rounded">
            <div className="w-[12.893rem] h-[10rem] mx-auto">
              <canvas
                id="captureImage"
                width="100%"
                height="100%"
                style={{ display: "none" }}
              />
              {image && <img src={image} width="100%" height="100%" />}
              {!image && (
                <video
                  id="videocamera"
                  width="100%"
                  height="100%"
                  autoPlay
                ></video>
              )}
            </div>
          </div>

          {!image && (
            <button
              onClick={handleCaptureImage}
              className="z-50 flex items-center justify-center border-2 border-white border-solid rounded-full cursor-pointer w-11 h-11"
            >
              <p className="w-8 h-8 bg-white rounded-full"></p>
            </button>
          )}
        </div>

        {!image && (
          <>
            {" "}
            <div className="relative mx-auto md:w-4/5">
              <input
                type="file"
                name="file"
                id="idCard"
                onChange={handleChange}
                accept=".png, .jpeg, .jpg"
                className="absolute overflow-hidden opacity-0 z-index--1"
              />
              <label
                className="w-full py-2 md:py-2.5 flex justify-center cursor-pointer items-center disabled:opacity-40 border-none bg-clrTextBlue dark:bg-white rounded-full px-4 mx-auto transition-all duration-300 active:bg-clrTextBlue dark:active:bg-white hover:bg-clrTextBlue dark:hover:bg-white hover:scale-105 focus:outline-none whitespace-nowrap"
                htmlFor="idCard"
              >
                <PaperClip width={15} height={15} className="mr-2 dark-pin" />
                <p
                  className={`text-xs font-semibold text-white dark:text-clrTextBlue whitespace-nowrap`}
                >
                  Upload scanned image here
                </p>
              </label>
            </div>
            <p className="mt-3 text-xs font-semibold text-center text-clrGray dark:text-clrPlaceholder dark:font-medium">
              JPG or JPEG only. Max 2MB File size
            </p>
          </>
        )}
        {fileName && (
          <div className="flex items-center justify-center gap-2 -mt-4 mb-4 py-1.5 bg-white rounded">
            <PhotographIcon />
            <p className="font-semibold text-x10 text-clrTextBlue">
              {fileName}
            </p>
          </div>
        )}
        {image && (
          <div className="mx-auto space-y-4 md:w-4/5">
            <Button
              type="button"
              text="Use this picture"
              handleClick={() => handleSubmit(image)}
            />
            <ButtonOutlineWallet
              type="button"
              text="Retake Picture"
              handleClick={() => setImage("")}
              wBtn="w-full"
              py="py-2"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

interface CaptureSelfieProps {
  handleCloseModal: () => void;
  handleSubmit: (val: string) => void;
}

export const CaptureSelfie: React.FC<CaptureSelfieProps> = ({
  handleSubmit,
  handleCloseModal,
}) => {
  const [image, setImage] = useState<string>("");
  const [mediaStream, saveStream] = useState<MediaStream>();

  const startCamera = async () => {
    try {
      const startCameraFunction = openCamera();
      const stream = await startCameraFunction();
      // Do something with the stream, e.g., display it on the page
      const videoElement = document.getElementById(
        "videocamera"
      ) as HTMLVideoElement;
      saveStream(stream);
      videoElement.srcObject = stream;
    } catch (error) {
      console.error("Error opening camera:", error);
    }
  };

  const handleCaptureImage = () => {
    try {
      const takePictureFunction = takePicture(mediaStream as MediaStream);
      const imageDataUrl = takePictureFunction();

      setImage(imageDataUrl);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  useEffect(() => {
    if (!image) {
      startCamera();
    }
  }, [image]);

  return (
    <Modal
      close={true}
      title={"Capture Selfie"}
      description={"Make sure your driving licence is infront of the frame"}
      handleCloseModal={handleCloseModal}
    >
      <div className="">
        <div className="flex flex-col items-center justify-center w-full gap-6 bg-black rounded mb-7 h-72">
          <div className="w-56 h-40 border-2 border-white border-solid rounded">
            <div className="w-[12.893rem] h-[10rem] mx-auto">
              <canvas
                id="captureImage"
                width="100%"
                height="100%"
                style={{ display: "none" }}
              />
              {image && <img src={image} width="100%" height="100%" />}
              {!image && (
                <video
                  id="videocamera"
                  width="100%"
                  height="100%"
                  autoPlay
                ></video>
              )}
            </div>
          </div>

          {!image && (
            <button
              onClick={handleCaptureImage}
              className="flex items-center justify-center border-2 border-white border-solid rounded-full cursor-pointer w-11 h-11"
            >
              <p className="w-8 h-8 bg-white rounded-full"></p>
            </button>
          )}
        </div>

        {image && (
          <div className="mx-auto space-y-4 md:w-4/5">
            <Button
              type="button"
              text="Submit Selfie"
              handleClick={() => handleSubmit(image)}
            />
            <ButtonOutlineWallet
              type="button"
              text="Retake Selfie"
              handleClick={() => setImage("")}
              wBtn="w-full"
              py="py-2"
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

interface ConfirmProps {
  handleCloseModal: () => void;
  handleSubmit: () => void;
  handleGoBack: () => void;
  isLoading: boolean;
  errMsg: string;
}
export const ConfirmApproval: React.FC<ConfirmProps> = ({
  handleCloseModal,
  handleSubmit,
  handleGoBack,
  isLoading,
  errMsg,
}) => {
  return (
    <Modal2
      close={true}
      title=""
      goBack={true}
      handleCloseModal={handleCloseModal}
      handleGoBack={handleGoBack}
    >
      <>
        <div className="flex flex-col items-center justify-center p-8 mt-4 bg-white dark:bg-clrDarkBg">
          <div className="flex items-center justify-center bg-[#EEE1A9] w-12 h-12 rounded-[50%]">
            <img src={TickSquare} alt="" width={30} height={30} />
          </div>
          <h1 className="my-6 text-base font-semibold text-clrTextBlue dark:text-white">
            Confirm Submission
          </h1>
          <p className="mb-5 font-normal text-center text-x10 text-clrGray dark:text-clrPlaceholder w-[80%]">
            Please keep in mind that presenting falsified documents is illegal
            and will be reported to the authorities.
          </p>
        </div>
        <ErrorMsg errMsg={errMsg} />
        <div className="md:w-[80%] mt-8 mx-auto ">
          <Button
            loading={isLoading}
            text="Confirm"
            type="button"
            handleClick={handleSubmit}
          />
        </div>
      </>
    </Modal2>
  );
};
