import { apiSlice } from "./apiSlice";
import { VerificationRequest } from "./homeSliceTypes";
interface SuccessResponse {
    success: boolean,
    data: [],
    errors: string[],
    message: string
}


const profileSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Verify user
        verifyUser: build.mutation<SuccessResponse, Partial<VerificationRequest>>({
            query: (payload) => ({
                url: `/v2/accountVerification/VerificationRequests/alternate`,
                method: "POST",
                body: payload
            }),
        }),

        //Update Profile information
        updateProfile: build.mutation<SuccessResponse, Partial<FormData>>({
            query: (payload) => ({
                url: `/v4/users/profile`,
                method: "PUT",
                body: payload
            }),
        }),
    }),
    overrideExisting: true,
})

export const { useVerifyUserMutation, useUpdateProfileMutation} = profileSlice