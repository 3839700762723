import { useState, useRef } from "react";
import { Button, DropDownContainer, ErrorMsg } from "../reusables";
import { Modal } from "../reusables/Modal";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down-Yellow.svg";
import { useGenerateTokenMutation } from "../../features/api/tokenSlice";
import { renderDays, renderHrs } from "../../Helper";

type GenerateTokenProps = {
  handleModal: () => void;
};

//Component to generate a token
export const GenerateToken: React.FC<GenerateTokenProps> = ({
  handleModal,
}) => {
  const modalRef = useRef<HTMLElement>(null);
  const [option, toggleOption] = useState<string>("");
  const [tokenType, setTokenType] = useState<string>("Data");
  const [dataUnit, setDataUnit] = useState<string>("");
  const [dataVolume, setDataVolume] = useState<string>("");
  const [tokenQuantity, setTokenQuantity] = useState<string>("");
  const [tokenValidity, setTokenValidity] = useState<string>("days");
  const [validityPeriod, setValidityPeriod] = useState<string>("");
  const [errorMsg, setError] = useState<string>("");

  const secondsInOneDay = 24 * 60 * 60;

  //Generate Tokens
  const [generateToken, { isLoading }] = useGenerateTokenMutation();

  const handleCloseModal = () => {
    toggleOption("");
  };

  useOutsideClick(modalRef, handleCloseModal);

  const handleGenerateTokens = () => {
    setError("");
    if (!tokenType) {
      setError("Please choose a token type");
    } else if (validityPeriod === "" && tokenType === "timed") {
      setError("Please indicate how long this timed token is valid for");
    } else if (dataVolume === "" && tokenType === "data") {
      setError("Please indicate the value of the data token");
    } else if (!tokenQuantity) {
      setError("Please set the quantity of tokens you are looking to generate");
    } else {
      console.log(validityPeriod, tokenValidity, "validityPeriod ish")
      let data = {
        customerId: "",
        secondsAllocated:
         tokenValidity === "days" && typeof validityPeriod === "number"
          ? validityPeriod * secondsInOneDay
          : tokenValidity === "days" && typeof validityPeriod === "string"
          ? 730 * secondsInOneDay // 730 days for 2 years
          : typeof validityPeriod === "number"
          ? validityPeriod * 60 * 60
          : 0,
        bytesAllocated:
          dataUnit === "GB"
            ? Number(dataVolume) * 1024 * 1024 * 1024
            : dataUnit === "MB"
            ? Number(dataVolume) * 1024 * 1024
            : Number(dataVolume) * 1024,
        codeValidityInSeconds: 0,
        dataTokenUsageType: tokenType,
        quantity: Number(tokenQuantity),
      };
      generateToken(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            handleModal();
          } else {
            setError(res.message);
          }
        })
        .catch((err) => {
          setError(err?.data.message);
        });
    }
  };
  return (
    <Modal close={true} title={"Generate Token"} handleCloseModal={handleModal}>
      <>
        <div className="flex flex-col gap-6 p-4 mb-4 bg-white rounded dark:bg-clrDarkBg md:px-5 md:pt-5 md:pb-8">
          <div>
            <Filter
              modalRef={modalRef}
              options={[
                { id: 1, value: "Data", enum: "Data" },
                { id: 2, value: "Timed", enum: "Timed" },
              ]}
              toggleFilter={toggleOption}
              selectedFilterOption={option}
              currentFilterOption={"TOKEN TYPE"}
              selectFilterOption={setTokenType}
              selectedOption={tokenType}
              label={"Token Type"}
            />
            <p className="mt-2 font-normal text-clrGray dark:text-clrPlaceholder md:text-x9 text-x10">
              What type of Token do you want to generate
            </p>
          </div>
          {tokenType === "Data" && (
            <div className="flex flex-wrap items-center justify-between md:flex-nowrap gap-y-6 gap-x-4">
              <div className="w-[80%]">
                <label
                  htmlFor="volume"
                  className="block mb-2 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                  Data Volume
                </label>

                <input
                  type="text"
                  name="volume"
                  value={dataVolume}
                  onChange={(e) => setDataVolume(e.target.value)}
                  placeholder="Enter Data Volume Here"
                  className="text-clrGray dark:placeholder:!text-clrPlaceholder dark:text-clrPlaceholder dark:bg-transparent placeholder:text-x11 text-x11 pb-1 font-normal border-b-[1px] border-solid border-clrBorder5 w-full focus:outline-none"
                />
              </div>
              <div className="w-[25%]">
                <Filter
                  modalRef={modalRef}
                  options={[
                    { id: 1, value: "TB", enum: "TB" },
                    { id: 2, value: "GB", enum: "GB" },
                    { id: 3, value: "MB", enum: "MB" },
                  ]}
                  toggleFilter={toggleOption}
                  selectedFilterOption={option}
                  currentFilterOption={"DATA UNIT"}
                  selectFilterOption={setDataUnit}
                  selectedOption={dataUnit}
                  label={"Data Unit"}
                />
              </div>
            </div>
          )}

          {tokenType === "Timed" && (
            <div className="space-y-6">
              <div className="flex items-center gap-8">
                <label
                  htmlFor=""
                  className="block mr-3 font-semibold text-black dark:text-white text-x10 md:text-xs"
                >
                  Validity
                </label>

                <div
                  className="relative flex items-center gap-2 radio-input"
                  onClick={() => {
                    setTokenValidity("days");
                    setValidityPeriod("");
                  }}
                >
                  <input
                    type="radio"
                    name="validity"
                    id=""
                    className=""
                    checked={tokenValidity === "days"}
                  />
                  {tokenValidity === "days" && (
                    <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                  )}
                  <label
                    htmlFor=""
                    className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                  >
                    Days
                  </label>
                </div>
                <div
                  className="relative flex items-center gap-2 radio-input"
                  onClick={() => {
                    setTokenValidity("hrs");
                    setValidityPeriod("");
                  }}
                >
                  <input
                    type="radio"
                    name="validity"
                    id=""
                    className=""
                    checked={tokenValidity === "hrs"}
                  />
                  {tokenValidity === "hrs" && (
                    <div className="circle !left-[0.19em] !top-[0.2em] !absolute"></div>
                  )}
                  <label
                    htmlFor=""
                    className="block font-medium text-clrGray dark:text-clrPlaceholder text-x10 md:text-xs"
                  >
                    Hours
                  </label>
                </div>
              </div>
              <div>
                <Filter
                  modalRef={modalRef}
                  options={
                    tokenValidity === "days" ? renderDays() : renderHrs()
                  }
                  toggleFilter={toggleOption}
                  selectedFilterOption={option}
                  currentFilterOption={"VALIDITY"}
                  selectFilterOption={setValidityPeriod}
                  selectedOption={
                    !validityPeriod
                      ? "Select Validity Period"
                      : validityPeriod + " " + tokenValidity
                  }
                  label={""}
                />
                <p className="mt-2 font-normal text-clrGray dark:text-clrPlaceholder md:text-x9 text-x10">
                  How long do you want the token to last
                </p>
              </div>
            </div>
          )}
          <div className="">
            <label
              htmlFor="quantity"
              className="block mb-2 font-semibold text-black dark:text-white text-x10 md:text-xs"
            >
              Quantity
            </label>

            <input
              type="text"
              name="quantity"
              onChange={(e) => setTokenQuantity(e.target.value)}
              placeholder="how may tokens do you want to generate"
              className="text-clrGray dark:text-clrPlaceholder dark:bg-transparent placeholder:text-x11 text-x11 pb-1 font-normal border-b-[1px] border-solid border-clrBorder5 placeholder:!text-clrPlaceholder dark:placeholder:text-clrPlaceholder w-full focus:outline-none"
            />
          </div>
          <ErrorMsg errMsg={errorMsg} />
        </div>

        <div className="w-4/5 mx-auto">
          <Button
            text="Generate"
            type="button"
            loading={isLoading}
            handleClick={handleGenerateTokens}
          />
        </div>
      </>
    </Modal>
  );
};

// Filter options
export const Filter: React.FC<DropdownContainerFilterProps> = ({
  options,
  toggleFilter,
  selectedOption,
  modalRef,
  selectFilterOption,
  selectedFilterOption,
  currentFilterOption,
  label,
}) => {
  return (
    <div className="relative mb-4 md:mb-0">
      <h4 className="mb-2 md:mb-2.5 font-semibold text-black dark:text-white text-x10 md:text-xs">
        {label}
      </h4>
      <button
        onClick={() => toggleFilter(currentFilterOption)}
        className="flex items-center justify-between relative w-full mt-[0.875rem] border-b-[1px] border-solid border-clrBorder5"
      >
        <p className="text-xs font-medium text-black dark:text-clrPlaceholder">
          {selectedOption}
        </p>
        <ArrowDown width={20} height={20} />
      </button>
      {selectedFilterOption === currentFilterOption && (
        <DropDownContainer
          list={options}
          modalRef={modalRef}
          closeDropdown={toggleFilter}
          handleSelect={selectFilterOption}
        />
      )}
    </div>
  );
};
