import { apiSlice } from "./apiSlice";
import { P2PFundingRequest, P2PFundingResponse, PendingOrderResponse } from "./authSliceTypes";
import { WalletBallanceResponse, AccountNameRequest,
    SupportedCurrencyResponse, CryptoDepositResponse, TransactionChargeResponse,
    UserBanksResponse, ConversionRateRequest, TransactionChargeRequest, ConversionRateResponse, ValidateAddressResponse,
    InitiateOrderResponse, InitiateCryptoWithdrawal,InitiateOrderRequest, InitiateCryptoWithdrawalResponse,
    WithdrawalOTPRequest, WithdrawalOTPResponse, BeneficiaryResponse, TransfertoBeneficiaryRequest, TransferBeneficiaryResponse,
    CryptoTransactionHistoryResponse, P2PTransactionHistoryResponse, TransferTransactionHistoryResponse, TransactionRequest, 
     TransferRecipient, BankList, SaveAccountRequest, SaveAccountResponse, AccountNameResponse, MinimumAmountResponse,
     SupportedChannelsRequest,
     SupportedChannelsResponse} from "./walletSliceTypes";

const walletSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({

        //Get balance details 
        getBalances: build.query<WalletBallanceResponse, string>({
            query: (userId) => `/v4/wallet-balance/user/${userId}`,
            providesTags: ['Vouchers', 'Wallet']
        }),

        //Get supported currencies
        getSupportedCurrencies: build.query<SupportedCurrencyResponse, string>({
            query: (transactionCategory) => `/v4/supported-currencies/${transactionCategory}`,
        }),

         //Get supported currencies for p2p transactions
        getSupportedP2PCurrencies: build.query<SupportedCurrencyResponse, string>({
            query: (orderType) => `/v4/supported-currencies/p2p/${orderType}`,
        }),

         //Get supported channels for a particular transaction
         getSupportedChannels: build.query<SupportedChannelsResponse, SupportedChannelsRequest>({
            query: ({category, currencyId}) => `/v4/supported-transaction-channels/${category}/currency/${currencyId}`,
        }),

        //Get crypto transaction History
        getCryptoTransactionHistory: build.query<CryptoTransactionHistoryResponse, TransactionRequest>({
            query: ({ userId, page, pageSize, status, type, currencyCode, reference, duration}) => 
            `/v4/transaction-history/user/${userId}/crypto?PageNumber=${page}`+ 
            `&PageSize=${pageSize}&Status=${status}&Type=${type}&CurrencyCode=${currencyCode}&Reference=${reference}&Duration=${duration}`,
            providesTags: ['Wallet']
        }),

        //Get p2p transaction History
        getP2pTransactionHistory: build.query<P2PTransactionHistoryResponse, TransactionRequest>({
            query: ({ userId, page, pageSize, status, type, currencyCode, reference, duration}) => 
            `/v4/transaction-history/user/${userId}/p2p?PageNumber=${page}`+ 
            `&PageSize=${pageSize}&Status=${status}&Type=${type}&CurrencyCode=${currencyCode}&Reference=${reference}&Duration=${duration}`,
            providesTags: ['Wallet']
        }),

        //Get transfer transaction History
        getTransferTransactionHistory: build.query<TransferTransactionHistoryResponse, TransactionRequest>({
            query: ({ userId, page, pageSize, status, currencyCode, reference, duration}) => 
            `/v4/transaction-history/user/${userId}/transfer?PageNumber=${page}`+ 
            `&PageSize=${pageSize}&Status=${status}&CurrencyCode=${currencyCode}&Reference=${reference}&Duration=${duration}`,
            providesTags: ['Wallet']
        }),

        //Get stakes transaction History
        getStakesTransactionHistory: build.query<TransferTransactionHistoryResponse, TransactionRequest>({
            query: ({ userId, page, pageSize, status, action}) => 
            `/v4/transaction-history/user/${userId}/stake?PageNumber=${page}`+ 
            `&PageSize=${pageSize}&Status=${status}&Action=${action}`,
            providesTags: ['Wallet']
        }),

        //Crypto Funding
        getAddressForCryptoFunding: build.query<CryptoDepositResponse, number>({
            query: (currencyId) => `/v4/payment-instructions/deposit/currency/${currencyId}`,
        }),

         //Validate wallet address
        validateAddressForCryptoWithdrawal: build.query<ValidateAddressResponse, string>({
            query: (walletAddress) => `/v4/withdrawal/wallet-address-type/${walletAddress}`,
        }),

        //Get all user's bank accounts 
        getUsersBankAccounts: build.query<UserBanksResponse, string>({
            query: (userId) => `/v4/bank-account/user/${userId}`,
            providesTags: ['Wallet']
        }),

        //Get banks
        getBanks: build.query<BankList, void>({
            query: () => `/v2/utils/banks`,
        }),

        //Get account name
        getAccountName: build.query<AccountNameResponse, AccountNameRequest>({
            query: ({acctNumber, bankId}) => `/v2/fiatTransfer/accountName?accountNumber=${acctNumber}&bankId=${bankId}`,
        }),

        // Save bank account details
        saveAccountDetails: build.mutation<SaveAccountResponse, Partial<SaveAccountRequest>>({
            query: (payload) => ({
            url: `/v2/userBankAccounts`,
            method: "POST",
            body: payload,
            }),
            invalidatesTags: ["Wallet"]
        }),

        //Get conversion rate
        getConversionRate: build.query<ConversionRateResponse, ConversionRateRequest>({
            query: ({outBoundCurrencyId, inboundCurrencyId}) => `/v4/p2p-orders/rate/outbound-currency/${outBoundCurrencyId}/inbound-currency/${inboundCurrencyId}`,
        }),

         //Get transaction charge
         getTransactionCharge: build.query<TransactionChargeResponse, TransactionChargeRequest>({
            query: ({transactionCategory, currencyId, channel, amount }) => `/v4/transaction-charges/transaction-category/${transactionCategory}/currency/${currencyId}/channel/${channel}?amount=${amount}`,
        }),

        //Initiate Order
        initiateOrder: build.mutation<
            InitiateOrderResponse,
            Partial<InitiateOrderRequest>
        >({
            query: ({payload, orderType, userId}) => ({
            url: `/v4/p2p-orders/${orderType}/user/${userId}`,
            method: "POST",
            body: payload
            }),
            invalidatesTags: ["Wallet"]
        }),

         //Initiate Order
        initiateCryptoWithdrawal: build.mutation<InitiateCryptoWithdrawalResponse,Partial<InitiateCryptoWithdrawal>>({
            query: ({payload, currencyId}) => ({
            url: `/v4/withdrawal/crypto/currency/${currencyId}`,
            method: "POST",
            body: payload
            }),
            invalidatesTags: ["Wallet"]
            
        }),

        //Send withdraawal OTP
        sendWithdrawalOTP: build.query<WithdrawalOTPResponse, WithdrawalOTPRequest>({
            query: ({currencyId, channel}) => `/v4/withdrawal/otp/currency/${currencyId}/channel/${channel}`,
        }),

        //Send p2p OTP
        sendP2POTP: build.query<WithdrawalOTPResponse, WithdrawalOTPRequest>({
            query: ({currencyId, channel}) => `/v4/p2p-orders/otp/currency/${currencyId}/channel/${channel}`,
        }),
        
         //Send OTP to the beneficiary
        sendTransferOTP: build.query<TransferBeneficiaryResponse, string>({
            query: (currencyId) => `/v4/transfers/otp/currency/${currencyId}`,
        }),

        //Get transfer beneficiary
        getTransferBeneficiary: build.query<BeneficiaryResponse, string>({
            query: (email) => `/v4/transfers/transfer-recipient?email=${email}`,
        }),

        //Transfer to a beneficiary
        transferToBeneficiary: build.mutation<TransferRecipient,Partial<TransfertoBeneficiaryRequest>>({
            query: ({payload, currencyId, recipientId}) => ({
            url: `/v4/transfers/currency/${currencyId}/recipient/${recipientId}`,
            method: "POST",
            body: payload
            }),
            invalidatesTags: ["Wallet"]
        }),

        //Get minimum amount for transaction
        getMinimumAmount: build.query<MinimumAmountResponse, void>({
            query: () => `/v2/P2POrder/xendbridgedetail`,
        }),

        //Confirm P2P funding
        confirmP2PFunding: build.query<P2PFundingResponse, P2PFundingRequest>({
            query: ({orderId, userId}) => `/v4/p2p-orders/order-status/${orderId}/user/${userId}`,
            providesTags: ['Wallet']
        }),

         //Checks if we have a pending order
        checkPendingOrder: build.query<PendingOrderResponse, string>({
            query: (userId) => `/v4/p2p-orders/open-order/user/${userId}`,
        }),

    }),
    overrideExisting: true,
})

export const { 
    useGetBalancesQuery, 
    useGetSupportedCurrenciesQuery,
     useGetAddressForCryptoFundingQuery,
     useGetUsersBankAccountsQuery,
     useGetBanksQuery,
     useLazyGetAccountNameQuery,
     useGetSupportedP2PCurrenciesQuery,
     useGetConversionRateQuery,
     useGetTransactionChargeQuery,
     useInitiateOrderMutation,
     useLazyValidateAddressForCryptoWithdrawalQuery,
     useLazySendWithdrawalOTPQuery,
     useLazyGetTransferBeneficiaryQuery,
     useInitiateCryptoWithdrawalMutation,
     useTransferToBeneficiaryMutation,
     useGetCryptoTransactionHistoryQuery,
     useGetP2pTransactionHistoryQuery,
     useGetTransferTransactionHistoryQuery,
     useGetStakesTransactionHistoryQuery,
     useLazySendTransferOTPQuery,
     useSaveAccountDetailsMutation,
     useGetMinimumAmountQuery,
     useLazyConfirmP2PFundingQuery,
     useCheckPendingOrderQuery,
     useLazyGetSupportedChannelsQuery,
     useLazySendP2POTPQuery
    } = walletSlice