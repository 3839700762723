import { useRef, useState } from "react";
import useOutsideClick from "../../Hooks/useOutsideClick";
import { ReactComponent as ChevronRightIcon } from "../../assets/icons/devices-chevron-right.svg";
import {
  Button,
  ButtonOutlineWallet,
  EmptyCard,
  // Filter,
  TableLoader,
} from "../reusables";
// import { MobileFilterSearch } from "../hotspotHubs/OptionsStatus";
import { SyncDevices, UnSync } from "./SyncDevice";
import { useNavigate } from "react-router";
import {
  useGetHotspothubQuery,
  useGetNFTsQuery,
} from "../../features/api/explorerSlice";

//Component to handle which tab is active at a time
const HotspotHubs = () => {
  const [tab, setTab] = useState<number>(1);

  return (
    <section className="w-[96%] mx-auto pt-3 pb-6 h-full overflow-y-auto">
      <div className="px-4 pt-2.5 pb-20 mb-2 bg-white dark:bg-clrDarkBg rounded-lg md:px-10">
        <div className="flex flex-wrap gap-3 mt-3">
          <ButtonOutlineWallet
            type="button"
            text="All"
            handleClick={() => setTab(1)}
            active={tab === 1}
            textSize="text-x9"
          />
          <ButtonOutlineWallet
            type="button"
            text="NFTs"
            handleClick={() => setTab(2)}
            active={tab === 2}
            textSize="text-x9"
          />
        </div>
        {tab === 1 ? <General /> : <NFT />}
      </div>
    </section>
  );
};

export default HotspotHubs;

// Component to handle showing all the hubs
const General: React.FC = () => {
  const [hubName, saveHubName] = useState("");

  //Get all hotspot hubs
  const { data: generalDevices, isLoading: gettingAllHubs } =
    useGetHotspothubQuery({
      deviceName: hubName,
      syncStatus: "",
      page: 1,
      pageSize: 30,
    });

  return (
    <div>
      <div className="flex flex-wrap w-full gap-x-3 gap-y-7 md:w-auto mt-7">
        <div className="flex-1 md:flex-auto ">
          <label
            htmlFor="hubname"
            className="hidden mb-2 font-semibold md:block text-x10 md:text-x11 text-clrGray dark:text-clrPlaceholder"
          >
            Hub Name
          </label>
          <input
            type="text"
            name="hubname"
            id=""
            placeholder="Search Name"
            value={hubName}
            onChange={(e) => saveHubName(e.target.value)}
            className="w-full md:w-52 text-xs font-medium text-clrGray border-[1px] border-solid border-clrPlaceholder dark:border-clrGray rounded-3xl h-7 placeholder:pb-1.5 px-4 placeholder:text-clrGray placeholder:text-x9 bg-inherit"
          />
        </div>
      </div>
      {gettingAllHubs ? (
        <TableLoader />
      ) : (
        <div className="mt-9">
          <h3 className="font-semibold text-x10 text-clrTextBlue dark:text-clrPlaceholder">
            Total Hubs<span>({generalDevices?.data.records.length})</span>
          </h3>
          <aside className="grid grid-cols-1 gap-3 pb-10 my-5 bg-white rounded-lg dark:bg-clrDarkBg md:grid-cols-3 md:gap-5">
            {generalDevices?.data.records.map((device) => {
              return (
                <Hubs
                  key={device?.deviceId}
                  nft={false}
                  handleSync={() => {}}
                  handleUnsync={() => {}}
                  device={device}
                />
              );
            })}
          </aside>
          {!gettingAllHubs && !generalDevices?.data.records.length && (
            <EmptyCard description={"NFTs Unavailable"} />
          )}
        </div>
      )}
    </div>
  );
};

const NFT: React.FC = () => {
  //const [option, toggleOption] = useState<string>("");
  // const [status, setStatus] = useState<string>("All");
  const [showSyncDevice, setshowSyncDevice] = useState<boolean>(false);
  const [showUnSyncDevice, setshowUnSyncDevice] = useState<boolean>(false);
  // const [hubName, saveHubName] = useState<string>("");
  const [tokenId, saveTokenId] = useState<number>(0);

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    //toggleOption("");
    setshowSyncDevice(false);
    setshowUnSyncDevice(false);
  };
  //Get hotspothubs backed by NFT
  const { data: nftDevices, isLoading: gettingNftDevices } = useGetNFTsQuery({
    page: 1,
    pageSize: 30,
  });

  const handleOpenSyncModal = (tokenId: number) => {
    saveTokenId(tokenId);
    setshowSyncDevice(true);
  };

  const handleCloseSyncModal = (tokenId: number) => {
    saveTokenId(tokenId);
    setshowUnSyncDevice(true);
  };

  useOutsideClick(modalRef, handleCloseModal);

  console.log(nftDevices, "nftDevices");

  return (
    <section>
      {/* <div className="flex flex-wrap w-full gap-x-3 gap-y-7 md:w-auto mt-7">
        <MobileFilterSearch>
          <Filter
            options={["All", "Synced", "Unsynced"]}
            toggleFilter={toggleOption}
            selectedFilterOption={option}
            currentFilterOption={"STATUS"}
            selectFilterOption={setStatus}
            selectedOption={status}
            modalRef={modalRef}
            label={"Status"}
          />
        </MobileFilterSearch>
        <div className="flex-1 md:flex-auto ">
          <label
            htmlFor=""
            className="hidden mb-2 md:2.5 font-semibold md:block text-x10 md:text-xs text-clrGray dark:text-clrPlaceholder"
          >
            Hub Name
          </label>
          <input
            type="text"
            name=""
            id=""
            value={hubName}
            onChange={(e) => saveHubName(e.target.value)}
            placeholder="Search Name"
            className="w-full md:w-52 text-xs border-[1px] border-solid border-clrPlaceholder dark:border-clrGray rounded-3xl h-8 placeholder:pb-1.5 px-4 placeholder:!text-clrGray placeholder:text-x10 bg-inherit"
          />
        </div>
      </div> */}
      {gettingNftDevices ? (
        <TableLoader />
      ) : (
        <div className="mt-9">
          <h3 className="text-xs font-semibold text-clrTextBlue dark:text-clrPlaceholder">
            Total Hubs<span>({nftDevices?.data.records.length})</span>
          </h3>
          {!gettingNftDevices && !nftDevices?.data.records.length && (
            <EmptyCard description={"NFTs Unavailable"} />
          )}
          <aside className="grid grid-cols-1 gap-3 pb-10 my-5 bg-white rounded-lg dark:bg-clrDarkBg md:grid-cols-3 md:gap-5">
            {nftDevices?.data.records.map((device) => {
              return (
                <Hubs
                  key={device.deviceId}
                  nft={true}
                  handleSync={handleOpenSyncModal}
                  handleUnsync={handleCloseSyncModal}
                  device={device}
                />
              );
            })}
          </aside>
        </div>
      )}
      {showSyncDevice && (
        <SyncDevices tokenId={tokenId} handleCloseModal={handleCloseModal} />
      )}
      {showUnSyncDevice && (
        <UnSync handleCloseModal={handleCloseModal} tokenId={tokenId} />
      )}
    </section>
  );
};

const Hubs: React.FC<HubsProps> = ({
  nft,
  handleUnsync,
  handleSync,
  device,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="grid grid-cols-3 gap-3 md:gap-4 bg-clrBg2 dark:bg-clrDarkBg2 rounded-[10px] p-2 md:p-4 shadow-wxl">
        <img
          src={device.imageUri}
          alt={device.deviceName}
          height={65}
          width={65}
          className="object-cover md:h-24 md:w-24 xl:w-[65px] rounded "
        />
        <div className="flex flex-col justify-between flex-1 h-full col-span-2">
          <div className="flex items-center justify-between pt-1 lg:pt-1.5">
            <p className="font-medium md:font-semibold text-x10 md:text-xs text-clrTextGray dark:text-white">
              {device.deviceName.slice(0, 15) +
                "..." +
                device.deviceName.slice(-4)}
            </p>
            <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder">
              {device.deviceTier}
            </p>
          </div>
          <p className="text-left text-x9 font-normal md:text-x9 text-[#9c9c9c] dark:text-clrPlaceholder">
            #{device.tokenId}
          </p>
          {nft && (
            <button
              onClick={() =>
                navigate(`/home/explorer/devices-name/${device.tokenId}`)
              }
              className="self-end"
            >
              <ChevronRightIcon />
            </button>
          )}
          <div className="justify-items-end md:w-[80%] mt-3 pb-1 lg:pb-1.5">
            {nft &&
              (device.isSynced ? (
                <ButtonOutlineWallet
                  text="Unsync"
                  type="button"
                  textSize="text-x9"
                  id="unsync"
                  handleClick={() => handleUnsync(device?.tokenId)}
                />
              ) : (
                <Button
                  handleClick={() => handleSync(device?.tokenId)}
                  text="Sync Hub"
                  type="button"
                  wBtn="w-fit"
                  fontWeight="font-normal"
                  py="py-1"
                  textSize="text-x8"
                />
              ))}
            {!nft && !device?.isSynced && (
              <a
                href="https://forms.office.com/r/iyYViE4bVf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  handleClick={() => {}}
                  text={"Request for NFT"}
                  type="button"
                  wBtn="w-fit"
                  fontWeight="font-normal"
                  py="py-1"
                  textSize="text-x8"
                />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
