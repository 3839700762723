import { apiSlice } from "./apiSlice";
import {
  AuthResponse,
  LoginDetails,
  EmailVerificationResponse,
  ResetRequest,
  SignUpDetails,
  GoogleLoginDetails,
  EmailVerification,
  ResetRequestResponse,
  PasswordReset,
  ValidateEmailVerificationResponse,
  ValidateEmailVerification,
  PasswordResetResponse,
  ReferralRequest,
  ReferralResponse,
  BusinessProfileResponse,
} from "./authSliceTypes";


const authSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({

    //Login a user
    logIn: build.mutation<AuthResponse, Partial<LoginDetails>>({
      query: (payload) => ({
        url: "/v2/auth/login",
        method: "POST",
        body: payload,
      }),
    }),

    //Generate Email Verification OTP
    generateEmailOTP: build.mutation<
      EmailVerificationResponse,
      Partial<EmailVerification>
    >({
      query: (payload) => ({
        url: `/v2/OTP/Generate/Email`,
        method: "POST",
        body: payload
      }),
    }),

    //Verify Email Verification OTP
    verifyEmailOTP: build.mutation<
      ValidateEmailVerificationResponse,
      Partial<ValidateEmailVerification>
    >({
      query: (payload) => ({
        url: `/v2/OTP/Validate/Email`,
        method: "POST",
        body: payload
      }),
    }),

    //Create account for a user
    signUp: build.mutation<AuthResponse, Partial<SignUpDetails>>({
      query: (payload) => ({
        url: "/v2/auth/signup",
        method: "POST",
        body: payload,
      }),
    }),

    //Login via Google
    logInWithGoogle: build.mutation<
      AuthResponse,
      Partial<GoogleLoginDetails>
    >({
      query: (payload) => ({
        url: `/v2/auth/${"Google"}/login`,
        method: "POST",
        body: payload,
      }),
    }),

    //Initialise Password Reset
    initialiseReset: build.mutation<
      ResetRequestResponse,
      Partial<ResetRequest>
    >({
      query: (payload) => ({
        url: `/v2/auth/password/forgot?email=${payload.email}`,
        method: "POST",
      }),
    }),

    //Reset Password
    resetPassword: build.mutation<PasswordResetResponse, Partial<PasswordReset>>({
      query: (payload) => ({
        url: `/v2/auth/password/reset`,
        method: "POST",
        body: payload,
      }),
    }),

    //Get User's referral list
    getReferrals: build.query<ReferralResponse, ReferralRequest>({
      query: ({ code, page, pageSize }) => `/v2/users/referrals/code/${code}?page=${page}&pageSize=${pageSize}`,
    }),

    //Get User's business 
    getBusinessProfile: build.query<BusinessProfileResponse, void>({
      query: () => `/v2/businesses/profile`,
    }),

    //Register a business
    registerBusiness: build.mutation<PasswordResetResponse, Partial<FormData>>({
      query: (payload) => ({
        url: `/v2/businesses`,
        method: "POST",
        body: payload,
      }),
    }),


  }),
  overrideExisting: true,
})

export const {
  useLogInMutation,
  useLogInWithGoogleMutation,
  useInitialiseResetMutation,
  useGenerateEmailOTPMutation,
  useVerifyEmailOTPMutation,
  useSignUpMutation,
  useResetPasswordMutation,
  useGetReferralsQuery,
  useGetBusinessProfileQuery,
  useRegisterBusinessMutation
} = authSlice;
