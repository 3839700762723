import { useState } from "react";
import { useAppSelector, useAppDispatch } from "../../app/hooks";
import ArrowDown from "../../assets/icons/arrow-down-Yellow.svg";
import { Button, ErrorMsg } from "../reusables";
import AddIcon from "../../assets/icons/wallet/add.svg";
import { BankAccount } from "./P2PInfo";
import {
  useGetConversionRateQuery,
  useGetSupportedP2PCurrenciesQuery,
  useGetMinimumAmountQuery,
} from "../../features/api/walletSlice";
import { setOutBoundCurrency } from "../../features/store/walletReducerSlice";
import { numberWithCommas } from "../../Helper";
import { Currency } from "../../features/api/walletSliceTypes";

interface P2PAmountProps {
  handleAmountConfirm: () => void;
  setOutBoundValue: (value: string) => void;
  outBoundValue: number;
  inBoundValue: number;
  setInboundValue: (value: string) => void;
  setSelectedBank: (bank: Bank) => void;
  selectedBank: Bank | null;
  setPhoneNumber: (value: string) => void;
  phoneNumber: string;
  type: "WITHDRAWAL" | "FUND";
  inboundCurrency: Currency;
  outboundCurrency: Currency;
}

const P2PAmount = ({
  handleAmountConfirm,
  setOutBoundValue,
  setInboundValue,
  setSelectedBank,
  selectedBank,
  setPhoneNumber,
  phoneNumber,
  outBoundValue,
  inboundCurrency,
  outboundCurrency,
  type,
}: P2PAmountProps) => {
  const dispatch = useAppDispatch();
  const [errorMsg, setError] = useState<string>("");
  // const { selectedCurrency: inboundCurrency, outboundCurrency } =
  //   useAppSelector((state) => state.wallet);

  const [isAddBank, setIsAddBank] = useState<boolean>(false);
  const [isp2pCurrencies, setShowCurrencies] = useState<boolean>(false);

  const { data: p2pcurrencies, isLoading: loadingCurrency } =
    useGetSupportedP2PCurrenciesQuery(type === "FUND" ? "Buy" : "Sell");
  const { data: minimumAmount, isLoading: gettingMinimumAmount } =
    useGetMinimumAmountQuery();

  const { data: rate } = useGetConversionRateQuery({
    inboundCurrencyId: inboundCurrency.id,
    outBoundCurrencyId: outboundCurrency.id,
  });

  const handleBankSelection = (bank: Bank) => {
    setSelectedBank(bank);
  };

  //Function to validate p2p
  const handleNext = () => {
    let minimum = minimumAmount?.data.minimumAmount || 0;
    //TODO: Validate that phone number does not contain text
    if (!outboundCurrency.name) {
      setError(
        `Please select a ${type === "FUND" ? "funding" : "withdrawal"} currency`
      );
    } else if (!phoneNumber) {
      setError("Please enter a valid phone number");
    } else if (outBoundValue < minimum) {
      setError(
        `Minimum ${type === "FUND" ? "fund" : "withdrawal"} amount is ${
          minimum + " " + outboundCurrency?.code
        }`
      );
    } else if (!selectedBank?.bankName) {
      setError("Please select a bank for the transaction");
    } else {
      setError("");
      if (type === "FUND") {
        setInboundValue((outBoundValue / (rate?.data?.rate || 0)).toString());
      } else {
        setInboundValue((outBoundValue * (rate?.data?.rate || 0)).toString());
      }
      handleAmountConfirm();
    }
  };

  return (
    <>
      <div className="p-4 bg-white rounded dark:bg-clrDarkBg md:px-8 md:py-5">
        <h2 className="text-sm md:text-base font-semibold text-[#212121] dark:text-white mb-1.5 md:mb-3">
          Amount
        </h2>
        <p className="font-normal text-x10 text-clrGray dark:text-clrPlaceholder">
          Enter the {type === "FUND" ? "funding" : "withdrawal"} amount
        </p>
        <div className="mt-6 md:px-3 md:mt-4">
          <label
            htmlFor=""
            className="block mb-1 font-medium md:mb-2 text-x10 text-clrTextBlue dark:text-clrPlaceholder"
          >
            Currency
          </label>
          <button
            className="flex justify-between items-center pb-1 w-full border-b-[0.7px] border-solid border-[#25346a99] dark:border-clrGray "
            onClick={() => setShowCurrencies(!isp2pCurrencies)}
          >
            <p className="font-semibold text-x10 text-clrGray opacity-60">
              {type === "FUND" ? outboundCurrency?.name : inboundCurrency?.name}
            </p>

            <img
              src={ArrowDown}
              alt="more options"
              width={20}
              height={20}
              className=""
            />
          </button>
          <div className="absolute z-20 w-1/5">
            {isp2pCurrencies && (
              <div className="p-3 space-y-2 bg-white border-2 rounded-md dark:bg-clrDarkBg border-clrBg">
                {loadingCurrency
                  ? "Loading.."
                  : p2pcurrencies?.data.currencies.map((currency) => {
                      return (
                        <div
                          className="flex items-center justify-between cursor-pointer"
                          key={currency.id}
                          onClick={() => {
                            dispatch(setOutBoundCurrency(currency));
                            setShowCurrencies(!isp2pCurrencies);
                          }}
                        >
                          <p className="font-medium text-x10 text-clrGray dark:text-clrPlaceholder">
                            {currency.name}
                          </p>
                        </div>
                      );
                    })}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-4 my-5 ">
          <p className="text-xs font-semibold text-clrGray opacity-60 dark:text-clrPlaceholder dark:font-medium">
            Click to enter amount
          </p>
          <span className="flex">
            <input
              value={Number(outBoundValue || 0)}
              className="text-5xl font-bold text-center text-black outline-none h-[3.2rem] md:text-6xl dark:text-white dark:placeholder:text-white dark:bg-clrDarkBg"
              style={{ width: `${outBoundValue.toString().length * 40}px` }}
              type="text"
              placeholder="0"
              onChange={(e) => setOutBoundValue(e.target.value)}
            />
            <span className="self-end pb-2 font-normal text-x10 text-clrGray md:pb-0">
              {outboundCurrency.code}
            </span>
          </span>

          <p className="text-xs font-semibold text-clrGray opacity-60 dark:font-medium dark:text-clrPlaceholder">
            You will receive
          </p>

          <p className="flex h-[3.5rem]">
            <span className="text-5xl font-bold text-black dark:text-white md:text-6xl">
              {" "}
              {rate && type === "FUND"
                ? numberWithCommas(outBoundValue / (rate?.data?.rate || 0))
                : numberWithCommas(outBoundValue * (rate?.data?.rate || 0))}
            </span>
            <span className="self-end font-normal text-x10 text-clrGray">
              {inboundCurrency.code}
            </span>
          </p>
          <p className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder">
            Minimum {type === "FUND" ? "fund" : "withdrawal"} amount:{" "}
            <span className="text-[#E5B910]">
              {" "}
              {gettingMinimumAmount
                ? "Loading..."
                : minimumAmount?.data.minimumAmount}{" "}
              {outboundCurrency.code}
            </span>
          </p>
        </div>

        <button
          onClick={() => setIsAddBank(true)}
          className="flex gap-3 w-full border-[#C4C4C4] dark:border-clrGray border-solid border-[1px] p-3.5 items-center rounded"
        >
          <img
            src={selectedBank ? selectedBank.iconUrlSvg : AddIcon}
            alt="Bank Logo"
            width={20}
            height={20}
          />
          <p
            className={`text-[10px]  mr-auto  ${
              selectedBank
                ? "font-semibold text-black dark:text-white"
                : "font-normal text-clrGray dark:text-clrPlaceholder"
            }`}
          >
            {selectedBank
              ? `${selectedBank.name} (${selectedBank.accountNumber})`
              : "Select a bank account"}
          </p>
          <img src={ArrowDown} alt="more options" width={20} height={20} />
        </button>

        <div className="mt-4 md:px-3">
          <label
            htmlFor=""
            className="block font-semibold dark:font-medium md:mb-2 text-x10 text-clrTextBlue dark:text-clrPlaceholder"
          >
            Phone Number
          </label>
          <input
            type="tel"
            name="phone"
            id="phone"
            value={phoneNumber}
            placeholder="08000000000"
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="text-x10 font-normal text-clrText   dark:!text-clrPlaceholder placeholder:!text-[#98989866] dark:placeholder:!text-clrPlaceholder dark:focus:!bg-clrDarkBg dark:!bg-clrDarkBg pb-1 w-full border-b-[0.7px] border-solid border-[#25346a99] dark:!border-clrGray focus:outline-none"
          />
        </div>
        <ErrorMsg errMsg={errorMsg} />

        <div className="md:w-[50%] mt-8 mx-auto ">
          <Button text="Continue" type="button" handleClick={handleNext} />
        </div>
      </div>
      <p className="text-[8px] font-normal text-clrText text-center opacity-40 mt-4">
        Powered by Xend Bridge{" "}
      </p>
      {isAddBank && (
        <BankAccount
          handleModal={() => setIsAddBank(false)}
          handleBankSelection={handleBankSelection}
        />
      )}
    </>
  );
};

export default P2PAmount;
